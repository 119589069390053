import {
  getFirestore,
  query,
  where,
  getDocs,
  collectionGroup,
} from "firebase/firestore";
import { firebaseApp } from "./firebase";

// Initialize Firestore
const db = getFirestore(firebaseApp);

const Papa = require("papaparse");

export async function exportToCSV(dateString) {
  const date = new Date(dateString);
  //remove time from date
  date.setHours(0, 0, 0, 0);
  //date one day before
  date.setDate(date.getDate() - 1);
  //create second variable with next day
  const nextDay = new Date(date);
  nextDay.setDate(nextDay.getDate() + 2);
  const sheep = query(
    collectionGroup(db, "newSheep"),
    where("createdAt", ">", date),
    where("createdAt", "<", nextDay)
  );
  const querySnapshot = await getDocs(sheep);
  console.log("count", querySnapshot.size);

  let dataArr = [];

  querySnapshot.forEach((doc) => {
    let data = doc.data();
    dataArr.push({
      Nombre: titleCase(data.firstName.trim()),
      Apellido: titleCase(data.lastName.trim()),
      Telefono: data.telephone,
      Biblia: data.bibleRedeemed,
      joined:
        titleCase(data.firstName.trim()) +
        " " +
        titleCase(data.lastName.trim()),
      fecha: data.createdAt.toDate().toLocaleDateString(),
      genero:data.sex,
      fechaNacimiento:data.birthDate
    });
  });

  // Convert to CSV
  let csv = Papa.unparse(dataArr);

  // Generate a Blob from the CSV string
  let csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  // Create a URL for the Blob
  let csvUrl = URL.createObjectURL(csvBlob);

  // Create a temporary anchor element and click it to start the download
  let tempLink = document.createElement("a");
  tempLink.href = csvUrl;
  const filename = getWeekRange();
  tempLink.setAttribute("download", filename);
    tempLink.click();
}

// helper function to title case a string
function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .filter(Boolean) // Remove empty strings
    .map((word) => word.replace(/^./, word[0].toUpperCase()))
    .join(" ");
}

function getWeekRange() {
  const today = new Date();
  const dayOfWeek = today.getDay();
  
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - dayOfWeek + 1); // +1 because we're considering Monday as the start

  const endOfWeek = new Date(today);
  endOfWeek.setDate(today.getDate() + (7 - dayOfWeek));

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return `Semana: ${formatDate(startOfWeek)}-${formatDate(endOfWeek)}.csv`;
}
