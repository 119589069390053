import React, { useCallback, useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import { coulums, csvOption } from "./SheepTableData";
import "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../util/firebase";
import { deleteSheep, updateSheep } from "../../util/db";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Table() {
  const columns = useMemo(() => coulums, []);

  const csvOptions = csvOption(columns);

  const csvExporter = new ExportToCsv(csvOptions);

  const [data, setData] = useState([]);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      const tempArr = [];
      const response = await getDocs(collection(db, "newSheep"));
      response.forEach((doc) => {
        let Mydoc = doc.data();
        Mydoc.id = doc.id;
        if ( Mydoc.sex === "H") {
          Mydoc.sex = "Hombre";
        } else if (Mydoc.sex === "M") {  //need to refactor this so its en english  not spanish
          Mydoc.sex = "Mujer";
        }
        // if (Mydoc.bibleRedeemed === "") {
        //   Mydoc.bibleRedeemed = "No";
        // }

        // if (Mydoc.coffeeRedeemed === "") {
        //   Mydoc.coffeeRedeemed = "No";
        // }

        tempArr.push(Mydoc);
      });

      setData(tempArr);
    };

    fetchData();
  }, []);

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    // validation for both qr and id
    if (data[row.index].id !== values.id || data[row.index].qr !== values.qr) {
      // Toast message should be presented here
      toast.error("QR and id can not be modifiable!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      exitEditingMode();
      return;
    }

    // Toast message should be presented here
    if (values.sex.toLowerCase() !== "m" && values.sex.toLowerCase() !== "f") {
      toast.error("Please enter valid data", {
        position: toast.POSITION.TOP_RIGHT,
      });
      exitEditingMode();
      return;
    }

    data[row.index] = values;
    setData([...data]);

    // handle update object from firebase
    updateSheep(values.id, values);
    exitEditingMode();
  };

  const handleDeleteRow = useCallback(
    (arrayOfRows) => {
      const choice = window.confirm(
        "Are you sure you want to delete these items?"
      );
      if (choice) {
        let arrOfDeletedRow = [];
        arrayOfRows.forEach((row) => {
          console.log(row.original.email);
          arrOfDeletedRow.push(row.original.id);
          deleteSheep(row.original.id);
        });

        setData(data.filter((obj) => !arrOfDeletedRow.includes(obj.id)));
      }
    },
    [data]
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      editingMode="modal"
      enableEditing
      onEditingRowSave={handleSaveRow}
      positionToolbarAlertBanner="bottom"
      enableRowSelection
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
        >
          <ToastContainer />
          <Button
            color="primary"
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export All Data
          </Button>
          <Button
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Exportar all rows
          </Button>
          {/* <Button
            disabled={table.getRowModel().rows.length === 0}
            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
            onClick={() => handleExportRows(table.getRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Page Rows
          </Button> */}
          <Button
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Exportar filas Selec
          </Button>

          <Button
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleDeleteRow(table.getSelectedRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="outlined"
            color="error"
          >
            Borrar Selec
          </Button>
        </Box>
      )}
    />
  );
}

export default Table;
